import React from "react"
import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import { useIntl, Link } from "gatsby-plugin-intl"


const Compare = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "site.title" })} />
      <section>
        <div className="wrapper">
          <div className="compare-header">
            <h3 className="header">{intl.formatMessage({ id: "compare.header.title" })}</h3>
            <ul>
              <li>{intl.formatMessage({ id: "compare.header.reason1" })}</li>
              <li>{intl.formatMessage({ id: "compare.header.reason2" })}</li>
              <li>{intl.formatMessage({ id: "compare.header.reason3" })}</li>
              <li>{intl.formatMessage({ id: "compare.header.reason4" })}</li>
              <li>{intl.formatMessage({ id: "compare.header.reason5" })}</li>
            </ul>
          </div>
          <div className="compare">
            <div className="compare-companies">
              <div>{intl.formatMessage({ id: "compare.body.ycost" })} <br /><div className="suffix">{intl.formatMessage({ id: "compare.body.notice" })}</div></div>
              <div>Dassport</div>
              <div>E-captain</div>
              <div>Markxman</div>
              <div>NSR (gestopt)</div>
            </div>
          </div>
          <div className="pricing-data background-secondary">
            <div>
              <div>{intl.formatMessage({ id: "compare.body.oneTime" })}</div>
              <div>-</div>
              <div>-</div>
              <div>€64</div>
              <div>€125</div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.yearly" })}</div>
              <div>€479</div>
              <div>€520</div>
              <div>€768</div>
              <div>€336</div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.perM" })}</div>
              <div className="suffix">- {intl.formatMessage({ id: "compare.body.btw" })}</div>
              <div>€1,53</div>
              <div>€1,73</div>
              <div>€2,56</div>
              <div>€1,12</div>
            </div>
          </div>
          <div className="compare-features">
            <div className="compare-pricing">
              <div>{intl.formatMessage({ id: "compare.body.griffier" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.presentie" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.portal" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.contactless" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.realtime" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.member" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.weapont" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.weapone" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.stock" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.article" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.subdomain" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div>€12,5</div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.domain" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div>€25</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.control" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.modern" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.billing" })}<span>*</span></div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.mail" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div><div className="tick">&#x2713;</div></div>
            </div>
            <div>
              <div>{intl.formatMessage({ id: "compare.body.evolving" })}</div>
              <div><div className="tick">&#x2713;</div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="compare-footnote">
            <ul>
              <li>{intl.formatMessage({ id: "compare.footnote.rights" })}</li>
              <li>{intl.formatMessage({ id: "compare.footnote.migration" })}</li>
            </ul>
            {/* <p>{intl.formatMessage({ id: "compare.footnote.rights" })}</p>
            <p>{intl.formatMessage({ id: "compare.footnote.migration" })}</p>
            <br /> */}
            <p>{intl.formatMessage({ id: "compare.footnote.ranges" })} <Link className="link link-active" offset="93" to='/#pricing-section'>({intl.formatMessage({ id: "nav.pricing" })})</Link></p>
            <p><span className="link-active">*</span> {intl.formatMessage({ id: "compare.footnote.development" })}</p>
            <br />
            <p>{intl.formatMessage({ id: "compare.footnote.possibilities.statement" })}<Link className="link link-active" offset="93" to='/#contact-form'>{intl.formatMessage({ id: "compare.footnote.possibilities.contact" })}</Link></p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Compare;